:root {
  --color-primary: #fae90c;
  --color-black: #000000;
  --color-white: #ffffff;
  --color-white-shade: #f6f6f6;
}

@font-face {
  font-family: american_typewriter;
  src: url(/fonts/AMERICAN_TYPEWRITER_REGULAR.TTF);
}

* {
  box-sizing: border-box;
  list-style-type: none;
}

body {
  margin: 0;
  padding: 0;
  font-family: american_typewriter;
  background-color: var(--color-white-shade);
}

.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
}

.container {
  width: 80%;
  margin: auto;
}

.main-wrapper {
}

.tooltip {
  position: absolute;
  bottom: 13px;
  transition: all 500ms linear;
  right: 10px;
  opacity: 0;
  display: inline-block;
}

.tooltip .tooltiptext {
  font-family: american_typewriter;
  visibility: hidden;
  width: 90px;
  right: 0%;
  background-color: var(--color-black);
  color: var(--color-white);
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  font-size: 14px;
  letter-spacing: 1px;
  top: 130%;
  opacity: 0;
  transition: all 0.5s;
  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
  top: 110%;
}

.logo-section {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.logo-section .logo-inside {
  position: relative;
  padding: 40px 15px;
  background-color: var(--color-white);
  border-radius: 10px;
}

.logo-section .logo-inside:hover .tooltip {
  opacity: 1;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.logo-section p {
  font-family: american_typewriter;
  font-size: 18px;
  color: var(--color-black);
  margin: 0;
}

.logo-section .logo-inside a {
  text-decoration: none;
}

.logo-section .logo-inside .content {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo-section .logo-inside .content img {
  cursor: pointer;
  width: 25px;
}

.logo-img {
  display: flex;
  min-height: 100px;
  align-items: center;
  justify-content: center;
}

.close {
  font-size: 20px;
}

iframe {
  display: block;
}

.logo-section .card {
  width: 30%;
}

.logo-section .card .pic {
  width: 348px;
  padding: 0px 10px;
}

.active_tab_title .title {
  text-align: center;
  padding: 20px 0px 40px;
  margin: 0;
}

.tab .button_section {
  display: flex;
  background-color: var(--color-primary);
  align-items: center;
  justify-content: center;
  padding: 0px 20px;
}

.tab .button_section h5 {
  display: flex;
  justify-content: center;
}

.tab .button_section h5 span {
  color: var(--color-black);
  margin: 0 20px;
  font-size: 20px;
  cursor: pointer;
  padding: 10px;
  font-family: american_typewriter;
}

.tab .button_section .active {
  border-bottom: 2px solid var(--color-black);
}

.categories {
  display: none;
  padding-bottom: 30px;
}
.categories.active {
  display: block;
}

@media screen and (max-width: 1200px) {
  .logo-section {
    margin: 0px 0px;
  }

  .main-wrapper {
    width: 100%;
  }
}

/* Media queries for screens smaller than 991px */
@media screen and (max-width: 991px) {
  .logo-section {
    grid-template-columns: repeat(3, 1fr);
  }
  .logo-section .logo-inside {
    padding: 20px 15px;
  }
}

/* Media queries for screens smaller than 767px */
@media screen and (max-width: 767px) {
  .logo-section {
    grid-template-columns: repeat(2, 1fr);
  }

  .logo-img {
    min-height: auto;
  }

  .tab .button_section h5 {
    display: block;
    overflow: scroll;
    white-space: nowrap;
    padding: 10px 20px;
  }

  .tab .button_section h5 span {
    margin: 0;
    padding: 10px 13px;
    text-align: center;
    display: inline-block;
    border-bottom: 2px solid transparent;
    font-size: 16px;
  }
}

/* Media queries for screens smaller than 480px */
@media screen and (max-width: 480px) {
  .logo-section {
    grid-template-columns: repeat(1, 1fr);
  }
}
